'use client';

// imports
import { useRef, useEffect, useState, memo, useCallback } from 'react';
import { useAtom } from 'jotai';
import { usePathname, useRouter } from 'next/navigation';
import useThrottledCallback from 'beautiful-react-hooks/useThrottledCallback';
import useWindowResize from 'beautiful-react-hooks/useWindowResize';

// lib files
import { FeaturedPreview } from '@/lib/types/api/show-data';
import { INTERNALLY_NAVIGATING_KEY } from '@/lib/constants';
import { signinModalAtom, SignInModalStateEnum } from '@/lib/atoms/signin-modal';
import { useHasMounted } from '@/lib/hooks';
import { userProfile, userProfileStatus } from '@/lib/atoms/profile';
import { VideoClass, RelatedEpisodeAsset, VideoTypeEnum } from '@/lib/types/api/video';
import { getQueryString } from '@/lib/helpers/get-query-string';
import { continuousPlay as continuousPlayAtom } from '@/lib/atoms/continuous-play';
import { sendPlayerPostMessage } from '@/lib/helpers/send-player-postmessage';
import { StationData } from '@/lib/types/api/stations-data';
import { useIsUserHoveringOverVideo, useIsPlayerReady } from '@/lib/hooks';
import CompanionState from '@/lib/types/atoms/companionState';

// helper functions
import { buildPlayerSrc, PlayerConfig } from './buildPlayerSrc';

// components
import PassportBenefitScreen from './PassportBenefitScreen';
import VideoPlayerOverlay from './VideoPlayerOverlay';
import MezzanineVideoInitializer from '@/components/MezzanineVideoInitializer/MezzanineVideoInitializer';
import UnavailableMessage from './UnavailableMessage';

// styles
import styles from './VideoPlayer.module.scss';
import { canAccessStorage } from '@/lib/helpers/is-storage-available';
interface PlayerIframeProps {
  iframeRef: React.RefObject<HTMLIFrameElement>;
  playerSource: string;
  title: string;
}

/**
 * PlayerIframe
 * A memoized component for our Player iFrame.
 * This was done in order to help prevent needless rerenders
 * of this expensive piece of UI.
 * @param {event} e - click event
 * @returns {boolean}
*/
const PlayerIframe = memo(function PlayerIframe(props: PlayerIframeProps) {
  const {
    iframeRef,
    playerSource,
    title
  } = props;
  return <iframe ref={iframeRef} src={playerSource} scrolling="no" title={title} name="player" className={styles.video_player_iframe} allow="autoplay; encrypted-media" loading="lazy" data-testid="video-player" allowFullScreen />;
});
interface VideoPlayerProps {
  className?: string;
  depStationData?: StationData;
  moveControlsUpBreakpoint?: number;
  playerConfig: PlayerConfig;
  showOverlay?: boolean;
  video: VideoClass | RelatedEpisodeAsset | FeaturedPreview;
}
const VideoPlayer = (props: VideoPlayerProps) => {
  const {
    className,
    depStationData,
    moveControlsUpBreakpoint,
    playerConfig,
    showOverlay = false,
    video
  } = props;
  const [profile] = useAtom(userProfile);
  const [profileStatus] = useAtom(userProfileStatus);
  const [_, setIsOpen] = useAtom(signinModalAtom);
  const [continuousPlay] = useAtom(continuousPlayAtom);
  const [playerSource, setPlayerSource] = useState('');
  const router = useRouter();
  const [pid, setPid] = useState('');
  const [isPassportMember, setIsPassportMember] = useState(false);
  const videoHasFlags = typeof (video as VideoClass).flags !== 'undefined';
  const isPassportVideo = videoHasFlags && (video as VideoClass).flags.is_mvod;
  const isNotPlayable = videoHasFlags && (video as VideoClass).flags.is_playable === false;
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const hasMounted = useHasMounted();
  const isProfileLoading = profileStatus === CompanionState.IsLoading;
  useEffect(() => {
    if (hasMounted && !isProfileLoading) {
      if (profile) {
        setPid(profile?.profile?.pid || '');
        setIsPassportMember(profile?.personal_data?.is_passport);
      }
    }
  }, [profile, isProfileLoading, hasMounted]);
  const pathname = usePathname();
  // we create the player src url on pathname change
  // this helps prevent weird race conditions between next.js and the browser
  useEffect(() => {
    // Applying user's continuous play settings from local storage
    if (!continuousPlay) {
      playerConfig.disableContinuousPlay = true;
    }
    if (canAccessStorage('sessionStorage')) {
      // reading session storage *here* to prevent weird re-renders of the iframe
      if (sessionStorage.getItem(INTERNALLY_NAVIGATING_KEY) === 'true') {
        playerConfig.autoplay = true;
      }
    }
    setPlayerSource(buildPlayerSrc(video, pid, isPassportMember, playerConfig));
  }, [pathname, isPassportMember, pid, playerConfig, video]);
  const isPlayerReady: boolean = useIsPlayerReady();
  const [isPlaying, setIsPlaying] = useState(false);

  // There are some contexts (e.g. Show Detail Heros) where we want to shift the controls up.
  // We supply the optional `moveControlsUpBreakpoint` prop to indicate that we should do
  // this, as well as what breakpoint above which we should.
  const handleIsAboveBreakpoint = () => {
    if (window.matchMedia(`(min-width: ${moveControlsUpBreakpoint}px)`).matches) {
      sendPlayerPostMessage('controlsUp');
    } else {
      sendPlayerPostMessage('controlsDefault');
    }
  };

  // Do this check on mount
  useEffect(() => {
    if (moveControlsUpBreakpoint && isPlayerReady) {
      handleIsAboveBreakpoint();
    }
  }, [moveControlsUpBreakpoint, isPlayerReady]);
  const onWindowResize = useWindowResize();

  // check if the viewport is above the desired breakpoint on resize
  onWindowResize(useThrottledCallback(() => {
    if (moveControlsUpBreakpoint) {
      handleIsAboveBreakpoint();
    }
  }));

  // We listen for post-messages coming from the embedded iframe Player
  // to synchronize the overlay visibility with Player controls
  const isUserHoveringOverVideo: boolean = useIsUserHoveringOverVideo();
  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    const userPressedSpacebar = e.key === " ";
    const message = JSON.stringify({
      command: 'toggle'
    });
    // grab the element currently in focus
    const focusEl = document.querySelector(':focus');
    // if an element is actually in focus (which won't be the case on a fresh page load)
    // use it's tagName, else use an empty string
    const focusTag = focusEl ? focusEl.tagName : ``;
    // make sure the focused element is not something that should respond to a spacebar normally
    const focusIsNotInputOrButton = focusTag !== `BUTTON` && focusTag !== `INPUT` && focusTag !== `TEXTAREA`;

    // toggle video playback if it's a spacebar press while not focused on a button or input
    if (userPressedSpacebar && focusIsNotInputOrButton) {
      e.preventDefault();
      // toggle video player
      if (iframeRef) {
        iframeRef.current?.contentWindow?.postMessage(message, '*');
      }
    }
  }, []);
  const handleSignInClick = useCallback((event: MessageEvent) => {
    const isPlayerSignInPostMessage =
    // does the message come from player?
    // adding this as a security check
    event.origin.indexOf('player.pbs.org') !== -1 &&
    // does it indicate that the user hit "sign in"?
    // this is the most brittle point of this feature.
    // if the sign in button stops working in player,
    // it likely changed this message data
    event.data === 'gtm-event:Passport,Sign In';
    if (isPlayerSignInPostMessage) {
      setIsOpen(SignInModalStateEnum.True);
    }
  }, [setIsOpen]);
  const handleMessage = useCallback((event: MessageEvent) => {
    let messageType;
    let upNextVideoSlug;
    if (typeof event.data === 'string') {
      if (event.data === 'gtm-event:Passport,Sign In') {
        return handleSignInClick(event);
      }
      if (event.data.match(/"command":"navigate-to-continuous-play-video"/)) {
        const messageData = event.data.match(/"payload":{"slug":"([^"]+)"}/);
        messageType = 'upNextVideoSlug';
        upNextVideoSlug = messageData ? messageData[1] : null;
      } else {
        const messageData = event.data.match(/"event":"videojs:([^"]+)"/);
        messageType = messageData ? messageData[1] : null;
      }
    }
    switch (messageType) {
      case 'upNextVideoSlug':
        if (upNextVideoSlug) {
          const queryString = getQueryString();
          const params = new URLSearchParams(queryString);
          if (params.get('continuousplayautoplay') && params.get('continuousplayautoplay') === 'true') {
            router.push(`/video/${upNextVideoSlug}/?continuousplayautoplay=true`);
          } else {
            router.push(`/video/${upNextVideoSlug}`);
          }
        }
        break;
      case 'play':
        setIsPlaying(true);
        break;
      case 'pause':
        setIsPlaying(false);
        break;
    }
  }, [handleSignInClick, router]);
  let classNames = `${styles.video_player_container}`;
  if (className) {
    classNames += ` ${className}`;
  }
  useEffect(() => {
    window.addEventListener('message', handleMessage);
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener('keydown', handleKeyPress);
    };
  });
  let mezzImgSrc = null;

  // sorry for all of these ts-ingores - we are dealing with a lot of different video types
  // @ts-ignore
  if (video.image) {
    // @ts-ignore
    mezzImgSrc = video.image;
    // @ts-ignore
  } else if (video.images && video.images['asset-mezzanine-16x9']) {
    // @ts-ignore
    mezzImgSrc = video.images['asset-mezzanine-16x9'];
    // @ts-ignore
  } else if (video.mezzanine16x9ImageUrl) {
    // @ts-ignore
    mezzImgSrc = video.mezzanine16x9ImageUrl;
  }
  let content = null;
  switch (true) {
    case videoHasFlags && !isPassportVideo && isNotPlayable:
      content = <>
          {/* Note the mezz image is kept separate from the unavailable messsage purely for CSS z-index reasons */}
          {mezzImgSrc && <MezzanineVideoInitializer videoType={VideoTypeEnum.Video} imgSrc={mezzImgSrc} alt={video.title} showWatchButton={false} onClick={() => {}} verticalOffsetButton={false} width={1440} className={styles.video_image} />}
          <UnavailableMessage />
        </>;
      break;
    case isPassportVideo && isProfileLoading:
      content = null;
      break;
    case isPassportVideo && !isProfileLoading && !isPassportMember:
      content = <>
          {mezzImgSrc && <MezzanineVideoInitializer videoType={VideoTypeEnum.Video} imgSrc={mezzImgSrc} alt={video.title} showWatchButton={false} onClick={() => {}} verticalOffsetButton={false} width={1440} className={styles.video_image} />}
          <PassportBenefitScreen video={(video as VideoClass)} depStationData={depStationData} />
        </>;
      break;
    case isPassportVideo && !isProfileLoading && isPassportMember:
    default:
      content = <PlayerIframe iframeRef={iframeRef} playerSource={playerSource} title={video.title} />;
      break;
  }
  return <div className={classNames} data-sentry-component="VideoPlayer" data-sentry-source-file="VideoPlayer.tsx">
      {showOverlay && <VideoPlayerOverlay video={(video as VideoClass)} isPlaying={isPlaying} isUserHoveringOverVideo={isUserHoveringOverVideo} />}
      {content}
    </div>;
};
export default VideoPlayer;